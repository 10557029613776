.dropdown {
    width:80%;
    position: relative;
    user-select: none;
    cursor: pointer;
}

.dropdown .dropdown-btn{
    padding:10px;
    background-color: #ffff;
    font-weight: bold;
    color: #333;
    display: flex;
    justify-content: space-between;
    color: #222B45;
    font: normal normal bold 12px/20px Poppins;
}
.dropdown .validDropdown{
    border:1px solid #EDF1F7;
}
.dropdown .inValidDropdown{
    border:1px solid #890804;
}
.invalidSelectItem{
    color:#890804 !important;
}
.selected-color-box{
    width: 15px;
    height: 15px;
    border-radius: 50%;
}
.dropdown .dropdown-content{
    max-height: 250px;
    overflow-y: auto;
    z-index: 1;
    position: absolute;
    padding:10px;
    background: #fff;
    box-shadow: 3px 3px 10px 6px rgba(0,0,0,0.06);
    color:#333;
    width: 100%;
    border-radius: 5px;
    font: normal normal medium 15px/28px SF Pro Display;
}
.dropdown .dropdown-content .dropdown-item{
    padding:5px;
    cursor: pointer;
    transition: all 0.2s;
    display: flex;
}
.dropdown .dropdown-content .dropdown-item:hover{
    background: #E8E7E7;
}
.dropdown-content .dropdown-item  img {
     width:22px;
     height: 22px;
    
}
.select-box-icon{
    width:22px;
    height: 22px;
}
.inner-padding{
    padding-left: 12px;
    overflow: hidden;
    display: -webkit-box;
    line-clamp: 2;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: break-all
}
.selected-item{
    display: flex;
    align-items: center;
}
.selected-value{
    padding-left:12px;
    word-break: break-all;
}
.drop-down-arrow{
    width:70%;
}
.drop-down-arrow-rotation{
    width:70%;
    transform: rotate(180deg);
}
.select-box-value{
    margin-left:12%;
    margin-right:7%;
    text-align:left;
    word-break: break-all;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}
.circle {
    height: 15px;
    width: 15px;
    border-radius: 50%;
    display: inline-block;
  }
.manage-icons-ctr {
    display: flex;
    height: 25px;
}
.dropdown-item {
    flex-direction: row;
}
.options-list {
    display: flex;
    width: 100%;
    align-items: center;
}
.data-update-delete-icon {
    width: 20px;
    height: 25px;
    cursor: pointer;
    color: #77838F;
}